import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import Img from 'gatsby-image';

import '../sass/pages/about-me.scss';

const AboutMe = ({ data }) => (
	<div className="wrapper-main">
		<SEO title="About Me" />
		<div className="wrapper-mastery">
			<div>
				<h2>Mastery</h2>
				<p>
					With many years of practice she has developed an expertise in the saw
					piercing technique. The essence of her designs keeps unfolding with
					each handmade piece she creates. Her collections have been presented
					in several distinguished international platforms.
				</p>
			</div>
			<Img fluid={data.calando.childImageSharp.fluid} />
		</div>
		<div className="wrapper-nature">
			<Img fluid={data.ambar.childImageSharp.fluid} />
			<div>
				<h3>Inspired by nature</h3>
				<p>
					Organic shapes displayed in a mystical way. Both delicate and strong
					such as nature patterns. Exuberant details and perfectly balanced
					designs.
				</p>
			</div>
		</div>
	</div>
);

export const query = graphql`
	query {
		calando: file(relativePath: { eq: "calando.jpg" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		ambar: file(relativePath: { eq: "ambar-rojo.jpg" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}		
	}
`;

export default AboutMe;
